<template>
  <div class="banner-holder">
    <img src="@/assets/jhhebanner.png">
  </div>
  <nav class="nav nav-pills justify-content-center">
    <router-link class="nav-link" :class="{ active: $route.path === '/'}" to="/">Home</router-link>
    <router-link class="nav-link" :class="{ active: $route.path === '/generalinfo'}" to="/generalinfo">General Information</router-link>
    <router-link class="nav-link" :class="{ active: $route.path.includes('edition')}" to="/editions">Past Editions</router-link>
    <router-link class="nav-link" :class="{ active: $route.path === '/authorinfo'}" to="/authorinfo">Author Information</router-link>
  </nav>
</template>

<style lang="scss" scoped>
.banner-holder img {
  max-width: 100%;
}
.nav-link {
  font-weight: bold;
}
.nav.nav-pills {
  padding-top: 5px;
  padding-bottom: 25px;
}
</style>