const convertToUriEncoded = (data) => Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')

const phpPost = async (url, data) => {
    return await fetch(url, {
        method: 'POST', 
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: convertToUriEncoded(data)
        })
}

export { convertToUriEncoded, phpPost }