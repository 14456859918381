<template>
  <div class="right-panel d-flex flex-column bd-highlight mb-3">
    <FeaturedEdition v-for="edition in featured" :edition="edition" :key="edition.number"></FeaturedEdition>
    <SignInWidget />
    <ViewCounter />
  </div>
</template>

<script>
import JournalData from '../helpers/journaldata'
import FeaturedEdition from './FeaturedEdition.vue'
import SignInWidget from './SignInWidget.vue'
import ViewCounter from './ViewCounter.vue'
export default {
  name: 'RightPanelMain',
  components: {
    FeaturedEdition,
    SignInWidget,
    ViewCounter
  },
  data () {
    return {
      journalData: JournalData
    }
  },
  computed: {
    featured () {
      return this.journalData.editions.filter((ed) => ed.volume >= this.journalData.currentVolume - 1 && ed.volume <= this.journalData.currentVolume )
        .sort((ed1, ed2) => (ed2.volume * 10 + ed2.number) - (ed1.volume * 10 + ed1.number)).slice(0, 2)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
