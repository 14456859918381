<template>
  <div class="hidden-view-counter" style="display:none">
  </div>
</template>

<script>
import Parse from 'parse/dist/parse.min.js';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'HiddenViewCounter',
  data () {
    return {
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    async load () {
      if (!this.count) {
        this.connectParse()
        var viewCounter = await Parse.Cloud.run("pageviewed", {})
        this.setCount(viewCounter.get('count'))
      }
    },
    ...mapMutations({
      setCount: 'setViewCount'
    }),
    ...mapActions({
      connectParse: 'connectParse'
    })
  },
  computed: {
    ...mapState({
      count: 'viewCount'
    })
  }
}
</script>
