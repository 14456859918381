<template>
  <div class="sign-in-widget">
    <div class="registration-header">
      Journal News Registration
    </div>
    <div class="login-area" v-if="showLogin">
      <div class="mb-3">
        <label for="emailInput" class="form-label">Email address</label>
        <input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp" v-model="loginName">
        <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
      </div>
      <div class="mb-3">
        <label for="passwordInput" class="form-label">Password</label>
        <input type="password" class="form-control" id="passwordInput" v-model="loginPassword">
      </div>
      <button type="button" class="btn btn-outline-dark" @click="login" :disabled="loggingIn">Sign-in/Register</button>
      <div class="login-problem" v-if="loginProblem!==null">
        {{loginProblem}}
      </div>
      <div class="reset-button-area" v-if="loginProblem==='Invalid Password'">
        <button type="button" class="btn btn-outline-dark" @click="resetPassword" :disabled="loggingIn">Reset Password</button>
      </div>
    </div>
    <div class="logged-in-area" v-else-if="justRegistered">
      Thank you for registering your email: {{loginName}}. A verifcation email has been sent to your address.
      <button type="button" class="btn btn-outline-dark" @click="verifiedEmail" :disabled="loggingIn">Verified</button>
      <div class="login-problem" v-if="loginProblem!==null">
        {{loginProblem}}
      </div>
    </div>
    <div class="logged-in-area" v-else-if="justReset">
      A password reset email has been sent for {{loginName}}. Please follow the instructions in the email to reset your password.
    </div>
    <div class="logged-in-area" v-else-if="parseConnected">
      <div class="needs-name-or-institution" v-if="!userRealName || !userInstitution">
        Please submit your name and institution to complete registration. <br><br>
        <div class="mb-3">
          <label for="nameInput" class="form-label">Full Name</label>
          <input type="text" class="form-control" id="nameInput" v-model="compedName">
        </div>
        <div class="mb-3">
          <label for="insitutionInput" class="form-label">Institution</label>
          <input type="text" class="form-control" id="insitutionInput" v-model="compedInstitution">
        </div>
      <button type="button" class="btn btn-outline-dark" @click="saveNameInstitution">Submit</button>
      <div class="login-problem" v-if="saveProblem!==null">
        {{saveProblem}}
      </div>
      </div>
      <div v-else>
        Thank you for registering {{userRealName}}
      </div>
    </div>
    <div class="waiting-for-parse" v-else>
    </div>
  </div>
</template>

<script>
import Parse from 'parse/dist/parse.min.js';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'sign-in-widget',
  data () {
    return {
      loginName: '',
      loginPassword: '',
      loginSuccess: false,
      loginProblem: null,
      loggingIn: false,
      justRegistered: false,
      justReset: false,
      user: null,
      username: '',
      internalName: '',
      internalInstitution: '',
      saveProblem: null
    }
  },
  mounted () {
    if (this.parseConnected) {
      this.initWidget()
    }
  },
  methods: {
    async initWidget () {
      try {
        const parseUser = Parse.User.current()
        if (parseUser) {
          this.user = await parseUser.fetch()
          if (this.user) {
            this.username = this.user.get('username')
            this.setLoggedIn();
            this.setCurrentUser(this.user)
          }
        } else {
          Parse.User.logOut()
        }
      } catch {
        Parse.User.logOut()
      }
    },
    async login () {
        this.loggingIn = true
        var user = null
        try {
          user = await Parse.User.logIn(this.loginName, this.loginPassword, { usePost: true })
        } catch (error) {
          if (error.message === 'Invalid username/password.') {
            user = new Parse.User()
            user.set('username', this.loginName);
            user.set('password', this.loginPassword);
            user.set('email', this.loginName);
            try {
              await user.signUp()
              await Parse.User.logOut()
              this.justRegistered = true
            } catch (registerError) {
              this.loginProblem = registerError.message === 'Account already exists for this username.' ? 'Invalid Password' : registerError.message
              this.loggingIn = false
              this.loginSuccess = false
              return
            }
          } else {
            this.loginProblem = error.message
            this.loggingIn = false
            this.loginSuccess = false
            return
          }
        }
        this.loggingIn = false
        this.loginSuccess = true
    },
    async resetPassword () {
      try {
        Parse.User.requestPasswordReset(this.loginName)
        this.justReset = true
      } catch (error) {
        this.loginProblem = error.message
      }
    },
    async saveNameInstitution () {
      if (this.compedName && this.compedInstitution) {
        var userToUpdate = await Parse.User.current().fetch()
        userToUpdate.set('name', this.compedName)
        userToUpdate.set('institution', this.compedInstitution)
        try {
          await userToUpdate.save()
          this.user = null
          this.user = await userToUpdate.fetch()
          this.setCurrentUser(this.user)
        } catch (saveError) {
          this.saveProblem = saveError.message
        }        
      } else {
        this.saveProblem = 'Please fill in both your Name and Institution.'
      }
    },
    async verifiedEmail () {
      this.loggingIn = true
      try {
        await Parse.User.logIn(this.loginName, this.loginPassword, { usePost: true })
        this.justRegistered = false
      } catch (error) {
        this.loginProblem = error.message
      }
      this.loggingIn = false
    },
    ...mapMutations({
      setLoggedIn: 'setLoggedIn',
      setCurrentUser: 'setCurrentUser'
    })
  },
  computed: {
    showLogin () {
      if (this.parseConnected) {
        var currentUser = Parse.User.current()
        return !currentUser && !this.loginSuccess && !this.justRegistered && !this.justReset
      }
      return false
    },
    userRealName () {
      return this.user ? this.user.get('name') : null
    },
    userInstitution () {
      return this.user ? this.user.get('institution') : null
    },
    compedName: {
      get () {
        if (this.internalName && this.internalName.length > 0) {
          return this.internalName
        }
        return this.userRealName
      },
      set (newVal) {
        this.internalName = newVal
      }
    },
    compedInstitution: {
      get () {
        if (this.internalInstitution && this.internalInstitution.length > 0) {
          return this.internalInstitution
        }
        return this.userInstitution
      },
      set (newVal) {
        this.internalInstitution = newVal
      }
    },
    ...mapState({
      parseConnected: 'parseConnected'
    })
  },
  watch: {
    parseConnected (newValue) {
      if (newValue) {
        this.initWidget()
      }
    },
    loginSuccess (newValue) {
      if (newValue) {
        this.initWidget()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in-widget {
  font-size: 14px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid black;
  
}
.form-control, .form-control:focus {
  background-color: antiquewhite;
}
.registration-header {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}
.logged-in-area {
  word-break: break-word;
}
</style>
