import { createStore } from 'vuex'
import Parse from 'parse/dist/parse.min.js';

export default createStore({
  state: {
    parseConnected: false,
    loggedIn: false,
    viewCount: null,
    currentUser: null
  },
  mutations: {
    parseIsConnected(state) {
      state.parseConnected = true
    },
    setViewCount(state, value) {
      state.viewCount = value
    },
    setLoggedIn(state) {
      state.loggedIn = true
    },
    setCurrentUser(state, value) {
      state.currentUser = value
    }
  },
  actions: {
    connectParse ({commit, state}) {
      if (!state.parseConnected) {
        Parse.initialize("N2yUtSlTWj7Yt3mWuvRZyz5pOMYpVC9l5edJ4GTG", "uKjsLkeaP5DGJb0ZhB1gB2CjC0iNXP2u0yIMXJwD")
        Parse.serverURL = "https://jhhe.b4a.io/"
        commit('parseIsConnected')
      }
    }
  },
  modules: {
  }
})
