<template>
  <div class="featured-edition d-flex flex-column mb-3">
    <div class="feature-title">
      {{title}}<br />
      Volume {{volume}} Number {{number}}<br />
      <span class="pubdate" v-if="pubDate">Published {{pubDate}}</span>
    </div>
    <img class="cover-img" :src="`${publicPath}covers/v${volume}n${number}.jpg`">
    <div class="feature-link">
      <a :href="pagesURL" target="_blank">Full Page Version</a>
    </div>
    <div class="feature-link">
      <a :href="spreadsURL" target="_blank">Spread Page Version</a>
    </div>
    <div class="feature-link">
      <router-link :to="`/edition/${volume}/${number}`">Articles</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturedEdition',
  props: ['edition'],
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    volume () {
      return this.edition.volume
    },
    number () {
      return this.edition.number
    },
    spreadsURL () {
      return this.edition.spreads
    },
    pagesURL () {
      return this.edition.fullPage
    },
    tocURL () {
      return this.edition.toc
    },
    title () {
      return this.edition.title
    },
    sections () {
      return this.edition.sections
    },
    pubDate () {
      return this.edition.pubDate
    }
  }
}
</script>

<style scoped lang="scss">
.featured-edition {
  border: 1px inset black;
}
.feature-title {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
}
.feature-link {
  padding-left: 5px;
  padding-right: 5px;
}
.cover-img {
  width: 100%;
  height: auto;
}
.pubdate {
  font-style: italic;
  font-size: 14px;
}
</style>