<template>
  <div class="view-counter">
    <span class="title">Site Views:</span> <span class="count">{{ count }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ViewCounter',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      count: 'viewCount'
    })
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
