import { convertToUriEncoded } from './generalhelpers'
import Parse from 'parse/dist/parse.min.js';

const adminPages = ['Contact Users', 'View Users']
const canUserAccess = async (destination) => {
  if (adminPages.includes(destination.name)) {
    var user = await Parse.User.current()?.fetch()
    if (user) {
        var data = {
            userid: user.id,
        }
        var response = await fetch(`/php/isAdminUser.php`, {
                method: 'POST', 
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
                body:  convertToUriEncoded(data)
                })
                var jsonResults = await response.json()
        return jsonResults ? 1 : -1
    }
    return 0
  }
  return 1
}

export { canUserAccess }