<template>
  <div class="container"><MainNav></MainNav></div>
  <router-view/>
  <div id="footer" class="position-absolute bottom-0">
    <div class="container maintained-by">
      <div class="row">
        <div class="col-4 text-start"><router-link class="footer-link" :class="{ active: $route.path === '/privacy'}" to="/privacy">Privacy Policy</router-link></div>
        <div class="col-8 text-end">Website developed and maintained by <a href="https://cryptictruth.com" target="_blank">CrypticTruth LLC</a>.</div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto">
          For technical assistance please contact: <a href="mailto: info@cryptictruth.com">info@cryptictruth.com</a>
        </div>
      </div>
      <HiddenViewCounter />
    </div>
  </div>
</template>

<script>
import MainNav from './components/MainNav.vue'
import HiddenViewCounter from './components/HiddenViewCounter.vue'
export default {
  name: 'MainApp',
  components: {
    MainNav,
    HiddenViewCounter
  }
}
</script>

<style lang="scss">
$primary: #070a25;
@import "~bootstrap/scss/bootstrap";

body {
  background: #2b2223 url("~@/assets/paper-banner2.png") repeat-y fixed center;
  font-weight: 600;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #040404;
  min-height: 100vh;
  position: relative;
  & p {
    font-size: 17px;
  }
  & a {
    font-weight: 600;
    font-size: 17px;
  }
  & h2 {
    font-size: 24px;
    font-weight: 600;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #040404;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.container.main-container {
  max-width: 850px;
  text-align: left;
  padding-bottom: 75px;
}
#footer {
  width: 100%;
  padding-bottom: 5px;
  & .maintained-by {
    border-top: 1px solid #343434;
    padding-top: 10px;
    line-height: 1.3;
    font-size: 14px;
  }
  & a {
    font-weight: 600;
    font-size: 16px;
  }
  & .container {
    max-width: 850px;
  }
}
</style>
