import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { canUserAccess } from '../helpers/gatehelpers'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/generalinfo',
    name: 'General Information',
    component: () => import(/* webpackChunkName: "generalinfo" */ '../views/GeneralInfo.vue'),
  },
  {
    path: '/editions',
    name: 'Editions',
    component: () => import(/* webpackChunkName: "archives" */ '../views/Archives.vue'),
  },
  {
    path: '/edition/:volume/:number',
    name: 'Edition Details',
    component: () => import(/* webpackChunkName: "editiondetails" */ '../views/EditionDetails.vue'),
    props: true
  },
  {
    path: '/authorinfo',
    name: 'Author Information',
    component: () => import(/* webpackChunkName: "authorinformation" */ '../views/AuthorInformation.vue'),
  },
  {
    path: '/contactusers',
    name: 'Contact Users',
    component: () => import(/* webpackChunkName: "contactusers" */ '../views/ContactUsers.vue')
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/UnauthorizedPage.vue')
  },
  {
    path: '/viewusers',
    name: 'View Users',
    component: () => import(/* webpackChunkName: "viewusers" */ '../views/ViewUsers.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/PrivacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  // canUserAccess() returns `true` or `false`
  const canAccess = await canUserAccess(to)
  if (canAccess === -1) return '/unauthorized'
  if (canAccess === 0) return '/'
})

export default router
