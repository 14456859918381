<template>
  <div class="home container main-container">
    <div class="row">
      <div class="col-sm-9">
        <h2>About The Journal...</h2>
<p>The Journal of Health and Human Experience is published by The Semper Vi
Foundation, an approved 501(c)(3) public charity. It is registered with the Library of
Congress. ISSN 2377-1577 (online). The Journal is a public access, free publication
funded only by charitable donations. The Journal has been incorporated into the libraries
of numerous universities and agencies across the globe.</p>
<p>An interdisciplinary, academic, peer reviewed international publication, the Journal
explores the fullness of holistic health within the meaning of human experience. Its
explorations richly convene all possible areas within the humanities and sciences, cultural
and social areas, technologies, ethics, law, social justice, and human rights. For more
information, go to the website's General Information link.</p>
<p>The Editorial Leadership welcomes academic and research investigations, reviews,
commentaries, creative writing such as poetry/short stories, new and emerging scholar
submissions, and other possibilities. The Journal makes use of a highly innovative four-stage academic review process that was created by members of the Journal leadership
beginning in 2006 with their service for another publication.</p>
<div class="describe-news">
  <div class="title-news">Journal News</div>
  To learn about newly published editions, calls for new manuscripts or other news
and initiatives, individuals have the option to register for Journal News. The
registration box is found immediately below the current edition on this, the Journal
home page. Registration information is protected in accordance with privacy and
confidentiality norms especially as found in the Journal Privacy Policy. For
questions about Journal News, email us at: <a href="mailto: JHHE@Sempervifoundation.org">JHHE@Sempervifoundation.org</a>. For
problems with registering, contact our web-executive at: <a href="mailto: info@cryptictruth.com">info@cryptictruth.com</a>.
</div>
<div class="order-copies">
  <div class="order-title">Journal Print/Order Special Information</div>
While the Journal is a free, public access, academic publication published by the Semper Vi
Foundation, print copies can be purchased independently from the Henry M. Jackson Foundation
for the Advancement of Military Medicine, Inc. (HJF), a 501(c)(3) nonprofit organization. All
funds from purchases, which cover printing and shipping costs, go directly to HJF and not the
Journal itself; the Journal is solely responsible for all content. To purchase any editions, past or
current, email HJF at: <a href="mailto: Design@hjf.org">Design@hjf.org</a>. You will need to provide the
Volume and Number for the edition(s) to be purchased.
</div>
<h2>What They're Saying About The Journal...</h2>
<p class="quote">"...a comprehensive interdisciplinary publication very relevant to human nature.
Regardless of one’s expertise, there is something from which an individual can benefit.
The articles are easy to read and should be used as often as possible in academic settings
and in seminars with smaller focus groups. The Journal is ideal for students in medical
school and special attention should be made regarding ethics."</p>

<div class="fst-italic text-end citation">Mildred Huff Ofosu, PhD<br />
Former Immunogeneticist and <br /> Post-Award Research Administrator<br />
Morgan State University (retired)</div>
<p class="quote">"The articles written within this journal are intellectually stimulating and very thought
provoking as it relates to how we practice medicine, how we treat our fellow humans and
even how we relate to pain and suffering. I am immediately reminded in each edition of
the need to teach our students how to be responsive to the world around us, understanding
that not everyone is on the same playing field."</p>

<div class="fst-italic text-end citation">Cedric M. Bright, MD, FACP<br />
Brody School of Medicine, East Carolina University<br />
112th President, National Medical Association</div>

<p class="quote">"...deeply reminds us on every page...of a higher, more human moral perspective.....of a better way of seeing and being with our patients and each other. Cover to cover, each
bite, of every single edition is simply inspirational. See for yourself."</p>

<div class="fst-italic text-end citation">Paul A. Finch, MSW, LCSW<br />
Director, Fleet and Family Support Center<br />
Naval Support Activity Bahrain</div>
<p class="quote">"An amazing variety of fascinating topics discussed in well-written articles, this journal
is true to its name."</p>

<div class="fst-italic text-end citation">Moni McIntyre, PhD<br />
Episcopal Priest<br />
Retired Faculty<br />
Duquesne University</div>
<p class="quote">".....uniquely successful in highlighting the interdisciplinary and very human nature of the
ongoing transformation of our nation’s health care system, especially the unprecedented
changes that patients, providers, and policy experts are experiencing…..truly visionary in
reflecting the complexity of 21 st century healthcare."</p>

<div class="fst-italic text-end citation">Pat DeLeon, PhD, MPH, JD<br />
Former President<br />
American Psychological Association</div>
<p class="quote">"Having spent 18 years working in bioethics at the Center for Practical Bioethics, I can
only laud the efforts expended to investigate a range of issues bursting with the potential
for analysis…..a fascinating, fact-filled publication waiting for your reading pleasure and
profit."</p>

<div class="fst-italic text-end citation">Rosemary Flanigan, CSJ, PhD. (retired)<br />
Professor Emerita<br />
Ethics Committee Education Program<br />
Center for Practical Bioethics </div>
<p class="quote">"…..a crucial resource for our time, especially where those in need and in poor health
suffer because of health disparities and systemic inequities. The Journal presents the
highest level of scholarship in the area of healthcare, but is exceptional because it exudes
a profound caring dimension that focuses on the human element."</p>

<div class="fst-italic text-end citation">Frederick W. Luthardt, DBE, MA<br />
Compliance Monitoring Program<br />
Office of Human Subjects Research<br />
Johns Hopkins School of Medicine</div>

<p class="quote">"Humanity is known for its creativity, innovation and physical, emotional and intellectual
strength. But at the same time humanity is also characterised by its vulnerability, fragility
and need for care. The Journal of Health and Human Experience navigates the reader to
be sensitive for the latter and guides how to deal with it."</p>

<div class="fst-italic text-end citation">Prof. Laetus OK Lategan, PhD, DTh<br />
Editor, Journal for New Generation Sciences<br />
Central University of Technology, South Africa</div>
      </div>
      <div class="col-sm-3">
        <RightPanelMain />
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import RightPanelMain from '@/components/RightPanelMain.vue'

export default {
  name: 'HomePage',
  components: {
    RightPanelMain
  }
}
</script>

<style lang="scss" scoped>
.citation {
  font-size: 16px;
  padding-bottom: 15px;
}
.quote {
  margin-bottom: 3px;
  margin-top: 5px;
}
.order-copies, .describe-news {
  font-size: 16px;
  border: 1px solid #040404;
  padding: 10px;
  margin-bottom: 15px;
}
.order-title, .title-news {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
}
</style>
